function debug(val: any, shouldBreak: boolean): void {
    if (useRuntimeConfig().public.nodeEnv === 'development') {
        if (shouldBreak) {
            try {
                const debugValue = JSON.parse(JSON.stringify(val));
                // eslint-disable-next-line no-console
                console.log(debugValue);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log('debug');
            }
        } else {
            try {
                const debugValue = JSON.parse(JSON.stringify(val));
                // eslint-disable-next-line no-console
                console.log(debugValue);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(val);
            }
        }
    }
}
export default debug;
