import { createGtm } from '@gtm-support/vue-gtm';

export default defineNuxtPlugin((nuxtApp) => {
    if (useRuntimeConfig().public.appEnv !== 'production') return
    nuxtApp.vueApp.use(createGtm({
        id: 'GTM-WL7MQVM',
        defer: false,
        compatibility: false,
        enabled: useAuth()?.status?.value === 'authenticated',
        debug: true,
        loadScript: true,
        vueRouter: useRouter(),
        trackOnNextTick: false,
    }))
})
