import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig()

    Sentry.init({
        app: nuxtApp.vueApp,
        autoSessionTracking: true,
        debug: config.public.ENV !== 'production',
        dsn: config.public.SENTRY_DSN,
        environment: config.public.SENTRY_ENVIRONMENT,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router as Router),
                tracePropagationTargets: [config.public.SENTRY_TRACE_PROPAGATION_TARGET],
            }),
            new Sentry.Replay(),
        ],
        trackComponents: true,
        hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.02,
    })

    return {
        provide: {
            sentrySetContext: Sentry.setContext,
            sentrySetUser: Sentry.setUser,
            sentrySetTag: Sentry.setTag,
            sentryAddBreadcrumb: Sentry.addBreadcrumb,
            sentryCaptureException: Sentry.captureException,
        },
    }
})
