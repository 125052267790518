import {defineNuxtPlugin} from "nuxt/app";
import debug from '~/assets/ts/debug';

export default defineNuxtPlugin(nuxtApp => {
    // now available on `nuxtApp.$injected`
    nuxtApp.provide('injected', () => 'my injected function')

    // You can alternatively use this format, which comes with automatic type support
    return {
        provide: {
            debug: (val: any = _, shouldDebug: boolean = false) => {
                debug(val, shouldDebug);
            }
        }
    }
})
