import revive_payload_client_4sVQNw7RlN from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOuQ1DYzjk from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import vuetify_plugin_8G5krGrqXM from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/.nuxt/vuetify.plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import debug_ZiGB8yHUre from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/plugins/debug.ts";
import gtm_client_OzmBuHLRIb from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/plugins/gtm.client.ts";
import pinia_48xmdi2HHl from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/plugins/pinia.ts";
import sentry_client_shVUlIjFLk from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_AOuQ1DYzjk,
  vuetify_plugin_8G5krGrqXM,
  chunk_reload_client_UciE0i6zes,
  debug_ZiGB8yHUre,
  gtm_client_OzmBuHLRIb,
  pinia_48xmdi2HHl,
  sentry_client_shVUlIjFLk
]