import { usePhaseStore } from '~/store/phase'
import {defineNuxtPlugin} from "nuxt/app";

export default defineNuxtPlugin(({ $pinia }) => {
    return {
        provide: {
            phase: usePhaseStore($pinia)
        }
    }
})
