import { defineNuxtPlugin } from '#imports';
import { createVuetify } from 'vuetify';

const opts = JSON.parse('{"theme":{"defaultTheme":"light","themes":{"light":{"colors":{"KANWOM":"#FDDE44","KANWOM-cta":"#97C23D","on-KANWOM-cta":"#FFFFFF","KANK":"#11A0C1","KANK-cta":"#97C23D","on-KANK-cta":"#FFFFFF","KANWB":"#97C23D","KANWB-cta":"#11A0C1","on-KANWB-cta":"#FFFFFF","KANS":"#BB7BB2","KANS-cta":"#97C23D","on-KANS-cta":"#FFFFFF","KANWR":"#F6AC43","KANWR-cta":"#97C23D","on-KANWR-cta":"#FFFFFF","KANQ":"#d24e29","KANQ-cta":"#97C23D","on-KANQ-cta":"#FFFFFF","KANNUM":"#42a7a9","KANNUM-cta":"#97C23D","on-KANNUM-cta":"#FFFFFF","kangoeroe-cta":"#97C23D","on-kangoeroe-cta":"#FFFFFF"}},"dark":{"colors":{"KANWOM":"#FDDE44","KANWOM-cta":"#97C23D","on-KANWOM-cta":"#FFFFFF","KANK":"#11A0C1","KANK-cta":"#97C23D","on-KANK-cta":"#FFFFFF","KANWB":"#97C23D","KANWB-cta":"#11A0C1","on-KANWB-cta":"#FFFFFF","KANS":"#BB7BB2","KANS-cta":"#97C23D","on-KANS-cta":"#FFFFFF","KANWR":"#F6AC43","KANWR-cta":"#97C23D","on-KANWR-cta":"#FFFFFF","KANQ":"#d24e29","KANQ-cta":"#97C23D","on-KANQ-cta":"#FFFFFF","KANNUM":"#42a7a9","KANNUM-cta":"#97C23D","on-KANNUM-cta":"#FFFFFF","kangoeroe-cta":"#97C23D","on-kangoeroe-cta":"#FFFFFF"}}}}}')

''

export default defineNuxtPlugin(nuxtApp => {
    const vuetify = createVuetify(opts)

    nuxtApp.vueApp.use(vuetify)

    return {
        provide: {
            vuetify
        }
    }
})