import { default as _91year_93Okb0Th4e6ZMeta } from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/pages/answers/debug/[personId]/[year].vue?macro=true";
import { default as loginuDd2e9BoXBMeta } from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/pages/auth/login.vue?macro=true";
import { default as logoutKkat6GHGRNMeta } from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/pages/auth/logout.vue?macro=true";
import { default as usere6VBAp91jfMeta } from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/pages/auth/user.vue?macro=true";
import { default as indexC45ngHL8f5Meta } from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/pages/index.vue?macro=true";
import { default as _91id_93qRyc7Yr62qMeta } from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/pages/question/[id].vue?macro=true";
import { default as resultsrMgeSMqxOzMeta } from "/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/pages/results.vue?macro=true";
export default [
  {
    name: _91year_93Okb0Th4e6ZMeta?.name ?? "answers-debug-personId-year",
    path: _91year_93Okb0Th4e6ZMeta?.path ?? "/answers/debug/:personId()/:year()",
    meta: _91year_93Okb0Th4e6ZMeta || {},
    alias: _91year_93Okb0Th4e6ZMeta?.alias || [],
    redirect: _91year_93Okb0Th4e6ZMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/pages/answers/debug/[personId]/[year].vue").then(m => m.default || m)
  },
  {
    name: loginuDd2e9BoXBMeta?.name ?? "auth-login",
    path: loginuDd2e9BoXBMeta?.path ?? "/auth/login",
    meta: loginuDd2e9BoXBMeta || {},
    alias: loginuDd2e9BoXBMeta?.alias || [],
    redirect: loginuDd2e9BoXBMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: logoutKkat6GHGRNMeta?.name ?? "auth-logout",
    path: logoutKkat6GHGRNMeta?.path ?? "/auth/logout",
    meta: logoutKkat6GHGRNMeta || {},
    alias: logoutKkat6GHGRNMeta?.alias || [],
    redirect: logoutKkat6GHGRNMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: usere6VBAp91jfMeta?.name ?? "auth-user",
    path: usere6VBAp91jfMeta?.path ?? "/auth/user",
    meta: usere6VBAp91jfMeta || {},
    alias: usere6VBAp91jfMeta?.alias || [],
    redirect: usere6VBAp91jfMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/pages/auth/user.vue").then(m => m.default || m)
  },
  {
    name: indexC45ngHL8f5Meta?.name ?? "index",
    path: indexC45ngHL8f5Meta?.path ?? "/",
    meta: indexC45ngHL8f5Meta || {},
    alias: indexC45ngHL8f5Meta?.alias || [],
    redirect: indexC45ngHL8f5Meta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qRyc7Yr62qMeta?.name ?? "question-id",
    path: _91id_93qRyc7Yr62qMeta?.path ?? "/question/:id()",
    meta: _91id_93qRyc7Yr62qMeta || {},
    alias: _91id_93qRyc7Yr62qMeta?.alias || [],
    redirect: _91id_93qRyc7Yr62qMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/pages/question/[id].vue").then(m => m.default || m)
  },
  {
    name: resultsrMgeSMqxOzMeta?.name ?? "results",
    path: resultsrMgeSMqxOzMeta?.path ?? "/results",
    meta: resultsrMgeSMqxOzMeta || {},
    alias: resultsrMgeSMqxOzMeta?.alias || [],
    redirect: resultsrMgeSMqxOzMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/sbiJJa92/0/vwo/kangoeroe-app/pages/results.vue").then(m => m.default || m)
  }
]