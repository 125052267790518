import { defineStore } from 'pinia'

export const usePhaseStore = defineStore('phase', {
    state: () => ({
        description: null,
        phase: null,
        year: null,
        endDate: null,
    }),
    actions: {
        set(value: any): void {
            this.description = value.description;
            this.phase = value.phase;
            this.year = value.year;
            this.endDate = value.endDate;
        },
    },
})
